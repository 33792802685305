import {Controlled as CodeMirror} from 'react-codemirror2'

// Language Modes
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/php/php';
import 'codemirror/mode/python/python';

// Editor Themes
import "codemirror/lib/codemirror.css";
import 'codemirror/theme/material.css';
import 'codemirror/theme/ambiance-mobile.css';
import 'codemirror/theme/base16-dark.css';
import 'codemirror/theme/zenburn.css';
import 'codemirror/theme/solarized.css';
import 'codemirror/theme/rubyblue.css';
import 'codemirror/theme/paraiso-dark.css';
import 'codemirror/theme/midnight.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/hopscotch.css';
import 'codemirror/theme/twilight.css';
import 'codemirror/theme/yeti.css';

export default CodeMirror;
